<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <new-client @add="addClientSidebar" :isSidebarActive="showNewClientSidebar"
                @closeSidebar="showNewClientSidebar= false"/>

    <new-address :ubigeo="ubigeo" :departments="departments" :provinces="provinces" :districts="districts"
                 :clients="[]"
                 :client="document.client"
                 @add="addAddressSidebar" :isSidebarActive="showAddressSidebar"
                 @closeSidebar="showAddressSidebar= false"/>

    <new-contact
        :clients="[]"
        :client="document.client"
        @add="addContactSidebar" :isSidebarActive="showContactSidebar"
        @closeSidebar="showContactSidebar= false"/>

    <new-product @add="addProductSidebar" :isSidebarActive="showNewProductSidebar"
                 @closeSidebar="showNewProductSidebar= false"/>

    <search :products="products" @add="addProductSidebar" :isSidebarActive="showSearchProductsSidebar"
            @closeSidebar="showSearchProductsSidebar= false"/>

    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Nueva cotización</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Client -->
        <div>
          <label class="text-sm opacity-75">Cliente*</label>
          <v-select @input="getClientData" :options="clients"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.client" class="mb-4 md:mb-0" name="client" id="client"
                    v-validate="'required'">
            <template #list-header>
              <li @click="showNewClientSidebar= true"
                  class="add-new-client-header d-flex align-items-center my-50"
              >
                <feather-icon
                    icon="PlusIcon"
                    size="16"
                />
                <span class="align-middle ml-25">
                  Nuevo cliente
                </span>
              </li>
            </template>
          </v-select>
          <span class="text-danger text-sm"
                v-show="errors.has('client')">{{ errors.first('client') }}</span>
        </div>
        <!--End-->

        <!--Address and contact--->
        <div class="vx-row">
          <!-- Address -->
          <div class="mt-5 vx-col w-1/2">
            <label class="text-sm opacity-75">Dirección*</label>
            <v-select :options="addresses"
                      label="alias"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.address" class="mb-4 md:mb-0" name="address" id="address"
                      v-validate="'required'">
              <template v-if="document.client" #list-header>
                <li @click="showAddressSidebar= true"
                    class="add-new-client-header d-flex align-items-center my-50"
                >
                  <feather-icon
                      icon="PlusIcon"
                      size="16"
                  />
                  <span class="align-middle ml-25">
                  Nueva dirección
                </span>
                </li>
              </template>
            </v-select>
            <span class="text-danger text-sm"
                  v-show="errors.has('address')">{{ errors.first('address') }}</span>
          </div>

          <!-- Contacts -->
          <div class="mt-5 vx-col w-1/2">
            <label class="text-sm opacity-75">Contacto</label>
            <v-select :options="contacts"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.contact" class="mb-4 md:mb-0" name="contact" id="contact"
            >
              <template v-if="document.client" #list-header>
                <li @click="showContactSidebar= true"
                    class="add-new-client-header d-flex align-items-center my-50"
                >
                  <feather-icon
                      icon="PlusIcon"
                      size="16"
                  />
                  <span class="align-middle ml-25">
                  Nuevo contacto
                </span>
                </li>
              </template>
            </v-select>
            <span class="text-danger text-sm"
                  v-show="errors.has('contact')">{{ errors.first('contact') }}</span>
          </div>
        </div>

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!---Currency, Decimals, IGV and offer validity-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/4">
            <div>
              <label class="text-sm opacity-75">Moneda*</label>
              <v-select :options="currencies"
                        label="value"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.currency" class="mb-4 md:mb-0" name="currency" id="currency"
                        v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('currency')">{{ errors.first('currency') }}</span>
            </div>
          </div>
          <div class="vx-col w-1/4">
            <div>
              <label class="text-sm opacity-75">Tipo de redondeo*</label>
              <v-select @input="calculateTotal" :options="roundingTypes"
                        label="code"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.roundingType" class="mb-4 md:mb-0" name="roundingType" id="roundingType"
                        v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('roundingType')">{{ errors.first('roundingType') }}</span>
            </div>
          </div>
          <div class="vx-col w-1/4">
            <div>
              <label class="text-sm opacity-75">IGV*</label>
              <v-select @input="calculateTotal" :options="igvTypes"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.igvType" class="mb-4 md:mb-0" name="igvType" id="igvType"
                        v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('igvType')">{{ errors.first('igvType') }}</span>
            </div>
          </div>
          <div class="vx-col w-1/4">
            <label class="text-sm opacity-75">Validez de oferta*</label>
            <v-select :options="deadlines"
                      label="value"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.offerValidity" class="mb-4 md:mb-0" name="offerValidity" id="offerValidity"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('offerValidity')">{{ errors.first('offerValidity') }}</span>
          </div>
        </div>
        <!--End-->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Payment type-->
        <div>
          <ul class="demo-alignment">
            <li>
              <vs-radio v-model="document.paymentType" name="paymentType" vs-value="regular">Regular</vs-radio>
            </li>
            <li>
              <vs-radio v-model="document.paymentType" name="paymentType" vs-value="fees">Cuotas</vs-radio>
            </li>
          </ul>
        </div>
        <!--End-->

        <!--Payment method-->
        <template v-if="document.paymentType === 'regular'">
          <div class="vx-row mt-5">
            <div class="vx-col w-1/2">
              <label class="text-sm opacity-75">Forma de pago*</label>
              <v-select :options="paymentMethods"
                        label="value"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.paymentMethod" class="mb-4 md:mb-0" name="paymentMethod" id="paymentMethod"
                        v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('paymentMethod')">{{ errors.first('paymentMethod') }}</span>
            </div>
          </div>
        </template>
        <!--End-->

        <!--Fees and frequency-->
        <div v-else-if="document.paymentType === 'fees'" class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <div>
              <label class="text-sm opacity-75">Cuotas*</label>
              <v-select :options="amountOfFees"
                        label="value"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.fees"
                        class="mb-4 md:mb-0"
                        name="fees"
                        id="fees"
                        v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('fees')">{{ errors.first('fees') }}</span>
            </div>
          </div>
          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Frecuencia*</label>
            <v-select :options="frequencies"
                      label="value"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.frequency" class="mb-4 md:mb-0"
                      name="frequency"
                      id="frequency"/>
          </div>
        </div>
        <!--End-->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Delivery and observation-->
        <div class="vx-row mt-5">
          <!--Delivery-->
          <div class="vx-col w-1/2">
            <vs-textarea v-validate="'required|max:255'" rows="2" name="delivery" label="Forma de entrega*"
                         v-model="document.delivery"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('delivery')">{{ errors.first('delivery') }}</span>
          </div>

          <!--Observation-->
          <div class="vx-col w-1/2">
            <vs-textarea v-validate="'max:255'" rows="2" name="observation" label="Observación"
                         v-model="document.observation"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('observation')">{{ errors.first('observation') }}</span>
          </div>
        </div>
        <!--End-->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Products-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <vs-button @click="showSearchProductsSidebar= true" :disabled="progress" class="w-full" color="danger"
                       type="filled">
              Buscar producto
            </vs-button>
          </div>
          <div class="vs-col w-1/2">
            <vs-button @click="showNewProductSidebar = true" :disabled="progress" class="w-full" color="danger"
                       type="border">
              Crear producto
            </vs-button>
          </div>
        </div>

        <!--Added products-->
        <vs-table class="mt-8" :data="addedProducts">

          <template slot="thead">
            <vs-th>Descripción</vs-th>
            <vs-th>Nro. de Parte</vs-th>
            <vs-th>Pl. de entrega(D)</vs-th>
            <vs-th>Garantía(M)</vs-th>
            <vs-th>Precio ({{ document.currency ? document.currency.symbol : '' }})</vs-th>
            <vs-th>Cantidad</vs-th>
            <vs-th>Eliminar</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td style="width: 40%" :data="tr.description">
                <vs-textarea rows="5" v-model="tr.description"></vs-textarea>
              </vs-td>

              <vs-td :data="tr.numberPart">
                <p> {{ tr.numberPart }}</p>
              </vs-td>

              <vs-td :data="tr.deliveryTerm">
                <vs-input style="width: 60px" type="number" v-model.number="tr.deliveryTerm"/>
              </vs-td>

              <vs-td :data="tr.warranty">
                <vs-input style="width: 60px" type="number" v-model.number="tr.warranty"/>
              </vs-td>

              <vs-td :data="tr.price">
                <template>
                  <vs-input min="1" style="width: 95px" type="number" v-model.number="tr.price"/>
                </template>
              </vs-td>

              <vs-td :data="tr.quantity">
                <template>
                  <vs-input style="width: 60px" min="1" type="number" v-model.number="tr.quantity"/>
                </template>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon class="ml-2" icon="TrashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="deleteProduct(tr)"/>
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>
        <!--End-->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-3/4"></div>
          <div class="vx-col w-1/4">
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">Sub total: </span>
              <span>
                {{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: this.document.currency.code,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(total / 1.18)
                }}
              </span>
            </div>
            <!--Divider-->
            <vs-divider class="mt-5"></vs-divider>
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">IGV: </span>
              <span>
                {{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: this.document.currency.code,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format((total / 1.18) * 0.18)
                }}
              </span>
            </div>
            <!--Divider-->
            <vs-divider class="mt-5"></vs-divider>
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">Total: </span>
              <span>
                {{
                  Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: this.document.currency.code,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(total)
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Generar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'
import NewClient from '@/views/clients/New'
import NewAddress from '@/views/clients/addresses/New'
import NewContact from '@/views/clients/contacts/New'
import NewProduct from '@/views/products/New'
import Search from '@/views/quotes/SearchProducts'

import departments from '../../data/ubigeo/departments.json'
import provinces from '../../data/ubigeo/province.json'
import districts from '../../data/ubigeo/district.json'
import ubigeo from '../../data/ubigeo/ubigeo.json'

import { auth, db, FieldValue, storage } from '@/firebase/firebaseConfig'
import axios from 'axios'

export default {
  name: 'NewQuote',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    clients: {
      type: Array,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    NewClient,
    NewAddress,
    NewContact,
    NewProduct,
    Search
  },
  mixins: [trimString],
  data () {
    return {
      document: {
        currency: {
          id: 2,
          value: 'Dólares américanos',
          symbol: '$',
          code: 'USD'
        },
        paymentMethod: {
          id: 30,
          value: 'Crédito a 30 días'
        },
        igvType: 'No incluye IGV',
        roundingType: 4,
        delivery: 'Donde indique dentro de lima metropolitana.',
        contact: {},
        address: {},
        offerValidity: '3 días',
        paymentType: 'regular',
        fees: {},
        frequency: {}
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      addresses: [],
      contacts: [],
      currencies: [
        {
          id: 1,
          value: 'Soles',
          symbol: 'S/',
          code: 'PEN'
        },
        {
          id: 2,
          value: 'Dólares américanos',
          symbol: '$',
          code: 'USD'
        }
      ],
      paymentMethods: [
        {
          id: 7,
          value: 'Crédito a 7 días'
        },
        {
          id: 30,
          value: 'Crédito a 30 días'
        },
        {
          id: 15,
          value: 'Crédito a 15 días'
        },
        {
          id: 20,
          value: 'Crédito a 20 días'
        },
        {
          id: 45,
          value: 'Crédito a 45 días'
        },
        {
          id: 60,
          value: 'Crédito a 60 días'
        },
        {
          id: 90,
          value: 'Crédito a 90 días'
        },
        {
          id: 1,
          value: '50% Adelantado 50% contra entrega'
        },
        {
          id: 31,
          value: '50% Adelantado 50% a 30 dias'
        },
        {
          id: 0,
          value: 'Contado'
        }
      ],
      deadlines: [
        '1 día',
        '2 días',
        '3 días',
        '4 días',
        '5 días',
        '6 días',
        '7 días',
        '8 días',
        '9 días',
        '10 días',
        '11 días',
        '12 días',
        '13 días',
        '14 días',
        '15 días',
        'OFERTA SOLO POR HOY'
      ],
      igvTypes: [
        'No incluye IGV',
        'Incluye IGV'
      ],
      roundingTypes: [
        4,
        5,
        6
      ],
      // Sidebars
      showNewClientSidebar: false,
      showAddressSidebar: false,
      showContactSidebar: false,
      showNewProductSidebar: false,
      showSearchProductsSidebar: false,
      // Fees
      amountOfFees: [
        {
          id: 2,
          value: '2 Cuotas'
        },
        {
          id: 3,
          value: '3 Cuotas'
        },
        {
          id: 4,
          value: '4 Cuotas'
        }
      ],
      frequencies: [
        {
          id: 15,
          value: 'Cada 15 días'
        },
        {
          id: 30,
          value: 'Cada 30 días'
        }
      ],
      // End
      // Ubigeo
      departments: departments,
      provinces: provinces,
      districts: districts,
      ubigeo: ubigeo,
      addedProducts: [],
      selected: null,
      total: 0
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.addedProducts = []
        this.document = {
          currency: {
            id: 2,
            value: 'Dólares américanos',
            symbol: '$',
            code: 'USD'
          },
          paymentMethod: {
            id: 30,
            value: 'Crédito a 30 días'
          },
          igvType: 'No incluye IGV',
          roundingType: 4,
          delivery: 'Donde indique dentro de lima metropolitana.',
          contact: {},
          address: {},
          offerValidity: '3 días',
          paymentType: 'regular',
          fees: {},
          frequency: {}
        }
        this.$validator.reset()
      }
    },
    addedProducts: {
      deep: true,
      handler () {
        this.calculateTotal()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          if (this.total !== 0) {
            this.progress = true
            // Calculate total
            const total = this.total
            const subTotal = parseFloat((this.total / 1.18).toFixed(2))
            const igv = parseFloat((total - subTotal).toFixed(2))
            // Get sequence
            const response = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/sequences-getSequenceForQuotes', {
              headers: {
                'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
              }
            })
            const sequence = response.data.sequence
            // End
            // Get exchange rate
            const responseExchange = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/exchangeRate-getExchangeRate', {
              headers: {
                'Authorization': 'Bearer 78b1deb4-1b27-4943-9d83-818285cc59d6'
              }
            })
            // End
            // Composite products
            this.addedProducts.forEach((p, index) => {
              if (p.type === 'Compuesto') {
                p.addedProducts.forEach((cp) => {
                  cp.finalQuantity = p.quantity * cp.quantity
                })
              }
              p.index = index
            })
            // End
            let obj = {
              ...this.document,
              state: true,
              status: 'hold',
              products: [
                ...this.addedProducts
              ],
              total,
              subTotal,
              igv,
              user: {
                displayName: auth.currentUser.displayName,
                uid: auth.currentUser.uid
              },
              code: 'CT-' + sequence,
              exchangeRate: responseExchange.data.venta
            }
            // Filter Products
            obj.filterProducts = []
            obj.products.forEach((p) => {
              obj.filterProducts.push(p.numberPart)
            })
            // End
            const doc = await db.collection('quotes').add({
              ...obj,
              createdAt: FieldValue.serverTimestamp()
            })
            obj.createdAt = new Date()
            obj.id = doc.id
            // Generate PDF
            const responsePdf = await this.generatePDF(obj)
            console.log(responsePdf)
            setTimeout(async () => {
              // Get document
              const link = await this.getPdfUrl(responsePdf.id, obj)
              await db.collection('quotes').doc(obj.id).update({
                pdfId: responsePdf.id,
                link
              })
              obj.link = link
              window.open(obj.link, '_blank')
              this.$emit('closeSidebar')
              this.$emit('add', obj)
              this.progress = false
              this.$vs.notify({
                color: 'success',
                title: 'Cotización',
                text: 'Cotización creada correctamente.'
              })
            }, 30000)
          } else {
            this.$vs.notify({
              color: 'warning',
              title: 'Cotización',
              text: 'Revisa cantidad y precio de productos.'
            })
          }
        }
      } catch (e) {
        this.progress = false
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      }
    },
    /**
     * Filter address by client
     */
    async filterAddressByClient () {
      if (this.document.client.id) {
        this.addresses = []
        this.addresses.push({
          address: this.document.client.address,
          alias: 'Principal',
          department: this.document.client.department,
          province: this.document.client.province,
          district: this.document.client.district,
          ubigeo: this.document.client.ubigeo,
          label: this.document.client.address + ' - ' + this.document.client.department + ' - ' + this.document.client.province + ' - ' + this.document.client.district
        })
        this.initProgress = true
        this.list = []
        const querySnap = await db.collection('clients').doc(this.document.client.id)
            .collection('addresses')
            .where('state', '==', true)
            .orderBy('createdAt', 'desc').get()
        querySnap.forEach((doc) => {
          let obj = {
            id: doc.id,
            address: doc.data().address,
            alias: doc.data().alias,
            department: doc.data().department,
            province: doc.data().province,
            district: doc.data().district
          }
          obj.label = obj.address + ' - ' + obj.department.nomDepartamento + ' - ' + obj.province.nomProvincia + ' - ' + obj.district.nomDistrito
          this.addresses.push(obj)
        })
        this.document.address = this.addresses[0]
        this.initProgress = false
      }
    },
    /**
     * Filter contact by client
     */
    async filterContactByClient () {
      if (this.document.client.id) {
        this.contacts = []
        this.initProgress = true
        this.list = []
        const querySnap = await db.collection('clients').doc(this.document.client.id)
            .collection('contacts')
            .where('state', '==', true)
            .orderBy('createdAt', 'desc').get()
        querySnap.forEach((doc) => {
          let obj = {
            id: doc.id,
            name: doc.data().name,
            lastName: doc.data().lastName
          }
          obj.label = obj.name + ' ' + obj.lastName
          this.contacts.push(obj)
        })
        if (this.contacts.length !== 0) {
          this.document.contact = this.contacts[0]
        }
        this.initProgress = false
      }
    },
    /**
     * Get client data
     * @returns {Promise<void>}
     */
    getClientData () {
      try {
        if (this.document.client.paymentMethod) {
          this.document.paymentMethod = this.document.client.paymentMethod
        }
        this.document.address = null
        this.document.contact = null
        this.filterContactByClient()
        this.filterAddressByClient()
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Client Sidebar
     */
    addClientSidebar (o) {
      this.clients.unshift({
        id: o.id,
        document: o.document,
        typeDocument: o.typeDocument,
        businessName: o.businessName,
        label: o.businessName + ' - ' + o.document,
        email: o.email,
        address: o.address,
        department: o.department,
        province: o.province,
        district: o.district,
        ubigeo: o.ubigeo
      })
    },
    /**
     * Address sidebar
     * @param o
     */
    addAddressSidebar (o) {
      let obj = {
        id: o.id,
        address: o.address,
        department: o.department,
        province: o.province,
        district: o.district
      }
      obj.label = obj.address + ' - ' + obj.department.nomDepartamento + ' - ' + obj.province.nomProvincia + ' - ' + obj.district.nomDistrito
      this.addresses.unshift(obj)
    },
    /**
     * Contact sidebar
     * @param o
     */
    addContactSidebar (o) {
      let obj = {
        id: o.id,
        name: o.name,
        lastName: o.lastName
      }
      obj.label = obj.name + ' ' + obj.lastName
      this.contacts.push(obj)
    },
    /**
     * Search product sidebar
     */
    addProductSidebar (o) {
      // let findProduct = this.addedProducts.find((p) => p.id === o.id)
      // if (!findProduct) {
      let obj = {
        id: o.id,
        description: o.description,
        sku: o.id.substr(0, 6).toUpperCase(),
        numberPart: o.numberPart,
        deliveryTerm: 7,
        warranty: 12,
        price: 0,
        quantity: 1,
        guide: 0,
        invoice: 0,
        purchaseOrder: 0,
        type: o.type,
        addedProducts: o.addedProducts ? o.addedProducts : []
      }
      // Composite product
      if (obj.type === 'Compuesto') {
        obj.addedProducts.forEach((o) => {
          o.finalQuantity = 0
          o.guide = 0
          o.invoice = 0
          o.purchaseOrder = 0
        })
      }
      this.addedProducts.push(obj)
      // } else {
      // findProduct.quantity++
      // }
      this.showSearchProductsSidebar = false
    },
    /**
     * Delete product
     */
    deleteProduct (product) {
      const indexProduct = this.addedProducts.findIndex((p) => p.id === product.id)
      this.addedProducts.splice(indexProduct, 1)
    },
    /**
     * Calculate total
     */
    calculateTotal () {
      this.total = 0
      this.addedProducts.forEach((p) => {
        if (this.document.igvType === 'No incluye IGV') {
          this.total += parseFloat((p.quantity * p.price * 1.18).toFixed(2))
        } else {
          this.total += parseFloat((p.quantity * p.price * 1.18).toFixed(2))
        }
      })
      this.total = parseFloat(this.total.toFixed(2))
    },
    /**
     * Generate PDF
     */
    async generatePDF (quote) {
      const products = []
      this.addedProducts.forEach((p) => {
        const obj = {
          description: p.description,
          numberPart: p.numberPart,
          deliveryTerm: p.deliveryTerm,
          warranty: p.warranty,
          quantity: p.quantity
        }
        if (quote.igvType === 'No incluye IGV') {
          obj.price = parseFloat((p.price).toFixed(quote.roundingType))
          obj.total = parseFloat((p.quantity * p.price).toFixed(quote.roundingType))
        } else {
          obj.price = parseFloat((p.price * 1.18).toFixed(quote.roundingType))
          obj.total = parseFloat((p.quantity * obj.price).toFixed(quote.roundingType))
        }

        obj.price = quote.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
          currency: quote.currency.code,
          minimumFractionDigits: quote.roundingType,
          maximumFractionDigits: quote.roundingType,
        }).format(obj.price)

        obj.total = quote.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
          currency: quote.currency.code,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(obj.total)

        products.push(obj)
      })

      // Doc name
      const timestamp = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate() + '-' + new Date().getHours() + '-' + new Date().getMinutes() + '-' + new Date().getSeconds()
      const docNameFinal = quote.code.toUpperCase() + '-' + timestamp + '.pdf'
      // End

      const response = await this.$http.post('https://api.pdfmonkey.io/api/v1/documents', {
        document: {
          document_template_id: 'E89048DD-45CB-49F4-9CAA-4709396413F4',
          payload: {
            'code': quote.code,
            'createdAt': `Lima ${new Date().getDate()} de ${this.getMonth(new Date().getMonth())} del ${new Date().getFullYear()}`,
            'businessName': quote.client.businessName,
            'currency': quote.currency.value,
            'businessDocument': quote.client.document,
            'businessAddress': quote.address.address + ', ' + quote.address.district + ', ' + quote.address.province + ', ' + quote.address.department,
            'businessContact': quote.contact ? quote.contact.label : '',
            'paymentMethod': quote.paymentType === 'regular' ? (quote.paymentMethod.value) : quote.fees.value + ' ' + quote.frequency.value,
            'offerValidity': quote.offerValidity,
            'userName': auth.currentUser.displayName,
            'userPhone': auth.currentUser.phoneNumber,
            'userEmail': auth.currentUser.email,
            'typeIgv': quote.igvType,
            'observation': quote.observation,
            'subTotal': quote.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: this.document.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(quote.subTotal),
            'igv': quote.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: this.document.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(quote.igv),
            'total': quote.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: this.document.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(quote.total),
            products
          },
          status: 'pending',
          'meta': {
            'clientId': 'ABC1234-DE',
            '_filename': docNameFinal
          }
        }
      }, {
        headers: {
          'Authorization': 'Bearer 8TU81ZSZyRqmcHrk4Q57'
        }
      })
      return response.data.document
    },
    /**
     * Get URL of monkey
     */
    async getPdfUrl (id, quote) {
      let response = null
      try {
        response = await this.$http.get(`https://api.pdfmonkey.io/api/v1/documents/${id}`, {
          headers: {
             'Authorization': 'Bearer 8TU81ZSZyRqmcHrk4Q57'
          }
        })
        // Save in firebase storage
        const timestamp = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate() + '-' + new Date().getHours() + '-' + new Date().getMinutes() + '-' + new Date().getSeconds()
        const docNameFinal = quote.code.toUpperCase() + '-' + timestamp + '.pdf'
        const responsePdf = await window.fetch(response.data.document.download_url)
        const storageRef = storage.ref(`quotes/${docNameFinal}`)
        const blob = await responsePdf.blob()
        const snapshot = await storageRef.put(blob)
        const url = await snapshot.ref.getDownloadURL()
        return url
      } catch (e) {
        return response.data.document.download_url
      }
    },
    /**
     * Get mont in string
     * @param month
     * @returns {string}
     */
    getMonth (month) {
      switch (month) {
        case 0:
          return 'Enero'
        case 1:
          return 'Febrero'
        case 2:
          return 'Marzo'
        case 3:
          return 'Abril'
        case 4:
          return 'Mayo'
        case 5:
          return 'Junio'
        case 6:
          return 'Julio'
        case 7:
          return 'Agosto'
        case 8:
          return 'Septiembre'
        case 9:
          return 'Octubre'
        case 10:
          return 'Noviembre'
        case 11:
          return 'Diciembre'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.add-new-client-header {
  padding: 8px;
  color: green;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 15px;
    margin-right: 4px;
  }

  &:hover {
    background-color: rgba(green, 0.12);
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 80vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
